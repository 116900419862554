<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <!-- <img :src="importedImg1" alt="" class="imgItem1" /> -->
      </div>
    </div>

  </div>
</template>

<script>
import "./index.css";
// import importedImg1 from "@/page/sinthorn/components/img/4_2.png";


export default {
  data() {
    return {
      // importedImg1: importedImg1,


      altText: "图片",
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
/* .imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
} */
img {
  display: block;
}
.img1,
.img2,
.img3,
.img4 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

/* .img3 {
  margin-left: 45px;
  margin-right: 45px;
} */
.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

/* .imgItem3 {
  max-width: 100%;
  margin-top: 80px;
} */
.imgItem4 {
  margin-top: 50px;
}
/* .imgItem3 {
  max-width: 100%;
  margin-bottom: 100px;
}
.imgItem4 {
margin-bottom: 100px;
} */
</style>
